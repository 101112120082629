import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import Img from 'gatsby-image'
import useSiteMetadata from '../components/SiteMetadata'
import Layout from '../components/Layout'
import LatestNews from '../components/LatestNews'
import StreamingList from '../components/StreamingList'
import EventsList from '../components/EventsList/EventsList'

import YouTube from 'react-youtube';

export const IndexPage = ({ data, location }) => {
  const { frontmatter } = data.markdownRemark
  const { title } = useSiteMetadata()

  const opts = {
    height: '390',
    width: '640',
    playerVars: {
      autoplay: 1,
      controls: 0,
      rel: 0,
      showinfo: 0,
      mute: 1,
      modestbranding: 1,
      iv_load_policy: 3,
      playsinline: 1,
      loop: 1,
      playlist: 'A310ic3u_UE',
    },
  }

  function onReady(e) {
    // access to player in all event handlers via event.target
    // e.target.pauseVideo();

    // on ready set the opacity to 1 to try and hide youtube playlist
    // 3 second delay to conteract youtube's timer
  }

  return (
    <Layout location={ location }>
      <Helmet>
        <title>{ `${title} | ${frontmatter.title}`}</title>
      </Helmet>
      <section
        className="hero pt-176 md:pt-416 bg-no-repeat bg-top bg-black relative overflow-hidden"
      >
        <Img 
          fluid={ data.file.childImageSharp.fluid } className="hero__image" 
          objectFit="cover" 
          objectPosition="50% 50%" 
          style={{position: "absolute"}}
        />

        <section className="hero__overlay">
          <div className="container flex flex-wrap flex-col space-y-32 text-white text-center py-24">
            <h3 className="text-12 md:text-16 text-white font-serif uppercase tracking-wider">
              {frontmatter.banner.subtitle}
            </h3>
            <h1 className="text-white font-serif uppercase">
              {frontmatter.banner.title}
            </h1>
            <div>
              <a className="inline-block py-8 px-40 lg:py-16 lg:px-64 rounded bg-red-200" href={frontmatter.banner.orderLink} target="_blank" rel="noreferrer">
                Order Now
              </a>
            </div>
          </div>
        </section>

        <section className="container mt-80 lg:my-176 relative">
          <StreamingList limit={ 4 } />
        </section>

        <section className="container pt-72 lg:py-40 relative">
          <h2 className="text-24 lg:text-32 font-serif uppercase tracking-wider mb-40">
            Latest News
          </h2>
          <LatestNews />
        </section>
      </section>

      <section className="pt-72">
        <div className="container">
          <h2 className="text-24 lg:text-32 font-serif uppercase tracking-wider mb-40">Featured Video</h2>
        </div>
        <div className="embed-responsive aspect-ratio-16/9">
          <div className="flex flex-col justify-end absolute h-full w-full z-20 bg-black bg-opacity-50">
            <div className="container pb-48">
              <h3 className="text-24 lg:text-32 font-extrabold uppercase tracking-wider mb-32">{ frontmatter.featuredVideo.videoTitle }</h3>
              <a href={ frontmatter.featuredVideo.videoLink } className="py-16 px-32 rounded bg-red-200" target="_blank" rel="noreferrer">
                Watch now
              </a>
            </div>
          </div>
          <YouTube containerClassName="pointer-events-none" videoId="A310ic3u_UE" opts={opts} onReady={onReady} />
        </div>
      </section>

      <section className="py-72 lg:py-48">
        <div className="container">
          <h2 className="text-24 lg:text-32 mb-40 font-serif uppercase tracking-wider">Upcoming Events</h2>
          <EventsList limit={ 2 }/>
        </div>
      </section>
    </Layout>
  )
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default IndexPage

export const pageQuery = graphql`
  query IndexPageTemplate {
    file(relativePath: {in: ["blg.jpg"]}) {
      childImageSharp {
        fluid(maxWidth: 1536, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        banner {
          title
          subtitle
          orderLink
        }
        featuredVideo {
          videoTitle
          videoLink
          videoID
        }
      }
    }
  }
`
