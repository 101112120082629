import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql, StaticQuery } from 'gatsby'
import { formatDistance } from 'date-fns'

class LatestNews extends React.Component {
  render() {
    const { data } = this.props
    const { edges: posts } = data.allMarkdownRemark

    return (
      <div className="grid grid-cols-6 md:grid-cols-12 gap-12 text-white">
        {posts && posts.map(({ node: post }, index) => {
            const postDate = formatDistance(new Date(post.frontmatter.date), new Date())
            
            return (
              <div className={ `col-span-6 lg:col-span-4 relative ${ index === 1 ? 'hidden lg:block' : ''}`} key={post.id}>
                <article
                  className={`blog-list-item tile is-child box notification ${
                    post.frontmatter.featuredpost ? 'is-featured' : ''
                  }`}
                >
                  <header>
                    <h3 className="post-meta">
                      <Link
                        className="block text-24 leading-32 mb-8"
                        to={post.fields.slug}
                      >
                        {post.frontmatter.title}
                      </Link>
                    </h3>
                  </header>
                  <p className="opacity-80 mb-56 leading-24">
                    {post.excerpt}
                  </p>
                  <div className="absolute bottom-0 flex space-x-32">
                    <Link className="text-red underline" to={post.fields.slug}>
                      Read full post
                    </Link>
                    <time dateTime={ post.frontmatter.date } className="opacity-50 italic">
                      { `${postDate} ago`}
                    </time>
                  </div>
                </article>
              </div>
            );
          })}
      </div>
    )
  }
}

LatestNews.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export default () => (
  <StaticQuery
    query={graphql`
      query LatestNewsQuery {
        allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: { frontmatter: { templateKey: { eq: "news-post" } } }
          limit: 3
        ) {
          edges {
            node {
              excerpt(pruneLength: 160)
              id
              fields {
                slug
              }
              frontmatter {
                title
                templateKey
                date(formatString: "MMMM DD, YYYY")
                featuredpost
                featuredimage {
                  childImageSharp {
                    fluid(maxWidth: 120, quality: 100) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <LatestNews data={data} count={count} />}
  />
)
